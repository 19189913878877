import './Portafolio.css';
// import { useState } from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import {LISTA} from './data/experiencias'
import React, { useState } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import Typed from 'react-typed';

function LandingPage() {


    return(
        <Col id="seraVerdad">
            <p style={{fontFamily: "'Red Hat Mono', monospace", fontSize: '13px', margin: '0px', fontWeight: '600'}}>Hi my name is ={'>'} {'{'}</p>
            <h1>Alejandro Farias</h1>
            <p style={ { fontSize: '24px'}}>I {'{'}<Typed strings={['Code', 'Debug', 'Deploy', 'Scale', 'Design', 'Assist', 'Clean', 'Teach', 'Learn']} loop='true' typeSpeed={100} backSpeed={100}></Typed>{'}'} things about Software and Robotics</p>
            <p class="textLorem" style={ { fontSize: '16px'}}>If you are here is because you must have been interested in something about me, let me tell you more in depth, come with me to know me</p>
            <p style={{fontFamily: "'Red Hat Mono', monospace", fontSize: '13px', margin: '0px', fontWeight: '600'}}>{'}'}</p>
            <Button variant='light' className='d-none d-xl-block'><a href="#aboutMe" style={{color: 'black', textDecoration: 'none'}}>Know me</a></Button>
            
            <div class="custom-shape-divider-bottom-1668140371 d-none d-lg-block">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
                    <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
                    <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
                </svg>
            </div>
        </Col>
    )
}

function Card({contenido , info, imagen1 = ''}) {
    return(
        <Container className='fullPage' id={info}>
            <Row className='insideContenido' style={ {margin: "auto"} }>
                <Col className="d-none d-lg-block">
                    {imagen1 !== '' ? <img src={`./assets/images/${imagen1}`} className='imagenLados'></img> : ''}
                </Col>
                <Col lg="10">
                    <div>{contenido}</div>
                </Col>
                <Col className="d-none d-lg-block"></Col>
            </Row>
        </Container>
    )
}

function AboutMe(){
    const imagen = require('./images/fotogris.png')
    const size = '100%'
    const imgStyle = {
        maxWidth: size,
        margin: 'auto'
    }


    return (
        <Row id='jesus'>

            <Col md={8}>
                <h1>About me</h1>
                <div>
                    <i><b>I am able to work in USA / CANADA / MEXICO</b></i>
                    <p><br/>+3 year Software Engineer able to learn new technologies without any problem.</p>
                    <p>  I consider myself a person with big goals and big dreams, capable of meeting expectations. </p>
                    <p>Always trying to improve myself, learning from my superiors as well as from the new ones, because I consider that everyone has something to teach us. </p>
                    <p>My ability to adapt to any culture and people helps me to be a good asset for any company.</p>
                </div>
            </Col>
            <Col md={4} className="d-none d-md-flex">
                <img src={imagen} style={imgStyle} alt="A portrait of me"></img>
            </Col>
        </Row>
    )
}

function Tarjeta( experiencia ){
    //De aqui quiero retornar la tarjeta nada mas, aqui solo saco una
    //Haz de cuenta que los datos nomas me llega de uno...
    const logo = {
        margin: 'auto',
        marginTop: '20px',
        marginBottom: '10px',
        width: "280px",
        height: "70px",
        padding: "0px 10px 0px 10px"
    }

    /* Aqui ponemos lo de colapsado */

    // console.log(window.screen.width);
    const estado = window.screen.width >= 1200 ? true : false;
    const [open, setOpen] = useState(estado)


    // console.log(open)

    return (
        <div className='tarjeta' onClick={() => setOpen(!open)} aria-controls="contenido-cerrado" aria-expanded={open}>
            <Row >
                <img src={experiencia.img} style={logo} alt='Company Logo'></img>
                {/* <p>{open.toString()}</p> */}
            </Row>

 
            {/* Esto es lo que quiero colapsar <1200px */}
            <Collapse in={open}>
                <div className='contenido' id='contenido-cerrado'>
                    <h4 style={{ textAlign: 'center', marginTop: '20px', marginBottom: '0px'}}>{ experiencia.title }</h4>
                    <i style={{fontSize: '14px', textAlign: 'left', paddingBottom: '50px'}}>{ experiencia.duration }</i>
                    <br/>
                    <br/>
                    <ul>
                        {experiencia.desc.map((item) => 
                        <li style={ { fontSize: '14px'}}> {item} </li>)}
                        {experiencia.links ? <Button href={experiencia.links} variant='dark' size='lg' className='neuBoton'>Project</Button>: ''}
                    </ul>
                </div>
            </Collapse>
        </div>
    )
}

/* Aqui le voy a meter el archivo de las experiencias y las voy a juntar todas */
 function AllExp( experiencias ) {
    return(
        <Row>
            {/* <h1 style={ {textAlign: 'center' }}>Experience</h1> */}
            <b className='d-block d-xl-none' style={{ textAlign: 'center' }}>Click for more details</b>
            {experiencias.map(item => 
            <Col xl={4} style={{display: 'flex'}} key={item}>{Tarjeta(item)}</Col>
            )}
        </Row>
    );
}
 
function NavBar() {

    // const [expanded, setExpanded] = useState(false);


    return (
        <Navbar bg="white" sticky='top' className='barra' expand="lg">
            <Container>
                <Navbar.Brand><img src='./logos/Icon.png' alt='Logo'></img></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" id='elmeroboton'/>
                <Navbar.Collapse id="responsive-navbar-nav " className='justify-content-end'>
                    <Nav className='justify-content-end mr-auto' style={{fontSize:'16px'}}>
                        <Nav.Link href='#landingPage'>{'{Home}'}</Nav.Link>
                        <Nav.Link href='#aboutMe'>{'{About me}'}</Nav.Link>
                        <Nav.Link href='#exps'>{'{Experience}'}</Nav.Link>
                        <Nav.Link href='#educacion'>{'{Education}'}</Nav.Link>
                        <Nav.Link href='#contact'>{'{Contact}'}</Nav.Link>
                        <Nav.Link href='./assets/pdf/CVSW_CarlosFarias.pdf' download="Resume" className='d-block d-lg-none'>{'{Resume}'}</Nav.Link>
                        <Button variant='outline-dark' href='./assets/pdf/CVSW_CarlosFarias.pdf' download="Resume" className='d-none d-lg-inline-block'>Resume</Button>
                        
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

function Education() {
    const textoEnMedio = {
        display: 'table-cell', 
        verticalAlign: 'middle',
        textAlign: 'justify'
    }

    return (
        <Row>
            <h1>Education</h1>
            <Col xl={8} style={{display: 'table'}}>
                <p style={textoEnMedio}>
                I studied my career at the University of Colima in the state of Colima, my major was in Mechatronics Engineering, which is a combination of Software, Electronics, Mechanical and Electrical Engineering.
                    <ul>
                        <li>6 months exchange to Deggendorf, Germany</li>
                        <li>Best generational average scholarship</li>
                    </ul>
                </p>
            </Col>
            <Col xl={4}>
                <div id="textoEducacion">

                    <img src="./assets/images/UdeC.png" href="https://www.ucol.mx/" alt="" style={{height: '140px', margin: 'auto'}} class=""/>
                    <br/>
                    <h4>Mechatronics Engineering</h4>
                    <h5>Bachelors of Science</h5>
                    <i>2017 - 2021</i>
                    <p></p>
                </div>
            </Col>
        </Row>
    )
}

function Contact() {


    return(
        <Col>
            <img src='./assets/images/team.svg' className='contact-imagen'></img>

            <div class="contactCard">

                <p class="informacionHeader">Email</p>

                <p class="informacion">cfariaslozoya@gmail.com</p>

            </div>

            <div class="contactCard">

                <p class="informacionHeader">Social Networks</p>

                <p class="informacion"> <a href="https://www.linkedin.com/in/cfariaslozoya/" target="_blank" rel="noreferrer"><img src="./assets/images/linked2.png" style={{width: '40px', height: '40px'}} alt=""/></a>  </p>

            </div>
        </Col>
        
    )
}


function FadeInSection(props){
    const [isVisible, setVisible] = React.useState(false);
    const domRef = React.useRef();
    React.useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => setVisible(entry.isIntersecting))
        })
        observer.observe(domRef.current)
    }, []);
    return (
        <div className={`fade-in-section ${isVisible ? 'is-visible' : ''}`} ref={domRef}>
            {props.children}
        </div>
    )
}

// function ScrollInto(destination){
//     const element = () => {

//     }
// }

export default function Componente() {
    // console.log(LISTA[1])
    return (
        <div>
            <NavBar/>
            <FadeInSection><Card contenido={ LandingPage() } info='landingPage'/></FadeInSection>
            <FadeInSection><Card contenido={ AboutMe() } info='aboutMe'/></FadeInSection>
            <FadeInSection><Card contenido={ AllExp(LISTA) } info='exps'/></FadeInSection>
            <FadeInSection><Card contenido={ Education() } info='educacion'/></FadeInSection>
            <FadeInSection><Card contenido={ Contact() } info='contact'/></FadeInSection>
        </div>
    )
}
