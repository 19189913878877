
const LISTA = [
    {
        id: 1,
        title: 'Project Manager',
        company: 'Tata Consultancy Services',
        duration: 'Oct 2019 - Jan 2020',
        desc: ['Successfully designed and implemented a hydraulic project for leak testing and maintenance of high volume cylinders, reducing logistic costs by $300,000 MXN', 
                'Built an end-to-end machine learning pipeline to automate the process of training, deploying, and monitoring models in production.',
                'Successfully increased the accuracy of a machine learning prediction model by 10% through feature engineering and hyperparameter tuning',],
        img: './logos/tracsa.png'
    },
    {
        id: 2,
        title: 'Jr Java Developer',
        company: 'Tata Consultancy Services',
        duration: 'Jun 2020 - Jun 2021',
        desc: ['Successfully identified the root cause of software errors and implemented preventative measures, decreasing customer complaints by 40%.', 
                'Trained new hires on company specific software applications, resulting in a decrease in training time by 30%',
                'Solved complex software issues for a referral network of an intercontinental hotel line, resulting in a decrease of help desk tickets by 35%',
                'Developed data dashboards to monitor & report software performance issues to upper management, leading to the implementation of new company-wide software standards.'],
        img: './logos/tcs.png'
    },
    {
        id: 3,
        title: 'Software Engineer II',
        company: 'Oracle Corporation',
        duration: 'Jun 2021 - Present',
        desc: ['Implemented a cloud-based network of recruiting in 10 countries, which improved the hiring process by 30%', 
                'Created automated testing scripts to reduce manual testing time by 30%',
                'Debugged and created new PL/SQL queries for data models, resulting in a decrease of errors by 50%.',
                'Successfully gathered requirements from various stakeholders, translated them into technical specifications and led the development team to deliver the project on time'],
        img: './logos/oracle.png',
        // links: 'https://eeho.fa.us2.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_1/requisitions?location=United%20States&locationId=300000000149325'
    },
    

]

export { LISTA };