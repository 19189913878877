import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Componente from './portafolio/Portafolio';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
  <React.StrictMode>
    {/* <link href='https://fonts.googleapis.com/css?family=Montserrat' rel='stylesheet'></link> */}
    <link rel="preconnect" href="https://fonts.googleapis.com"/>
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
    <link href="https://fonts.googleapis.com/css2?family=Roboto&display=swap" rel="stylesheet"></link>
    <title>Alejandro Farias</title>
    {/* <App /> */}
    <Componente/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
